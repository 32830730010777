<template>

<div class="train train-img-w" :class="this.userSetting.eye_care ? 'bjColor' : ''">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="train-cont">
    <div class="left">
      <div class="left-top" :class="{nrBjColor: this.userSetting.eye_care ,'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}">
        <div v-if="!infoLoading">
          <div class="tit">
            <span>{{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{$t('trainingcamp_train_topic')}}</span>
            <span>{{ typesObj[dataInfo.datasetData.type]}}{{$t('trainingcamp_train_topic')}}</span>
<!--            <span v-if="dataInfo.datasetData.type != 1 && dataInfo.datasetData.type != 2 && dataInfo.datasetData.type != 3">({{$t('trainingcamp_train_no_answer')}})</span>-->
          </div>
          <div class="name"  style="display: inline-block;">

            <Richtxt :dataObj="{val:dataInfo.datasetData.name}" :isInline="true" :isDisabled="true"></Richtxt>
            <!--          {{ dataInfo.datasetData.name }}-->
          </div>
          <div class="cont"  v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3 || dataInfo.datasetData.type == 4">
            <!--        单选-->
            <RadioGroup v-model="radio" vertical v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 3" @on-change="changeRadio">
              <Radio :disabled="!modifiable" :label="optionList[index] + ': ' + item.name" class="option" :class="{'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}" v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                <div  style="display: inline-block;width: calc(100% - 22px);"><p style="display: flex;justify-content: flex-start"><span>{{optionList[index]}}:</span>
                  <span style="display: inline-block;">
                  <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                </span>
                </p></div>
              </Radio>
            </RadioGroup>
            <!--          多选-->
            <CheckboxGroup v-model="checkbox" v-if="dataInfo.datasetData.type == 2">
              <Checkbox :disabled="!modifiable" :label="optionList[index] + ': ' + item.name" class="checkoutOption" :class="{'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}"  v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                <div  style="display: inline-block;width: calc(100% - 22px);"><p style="display: flex;justify-content: flex-start"><span>{{optionList[index]}}:</span>
                  <span style="display: inline-block;">
                  <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>

                </span>
                </p></div>
              </Checkbox>
            </CheckboxGroup>
            <div v-if="dataInfo.datasetData && dataInfo.datasetData.type == 4">
              <Input class="cont-input" :disabled="!modifiable" size="large" v-model="item.myAnswer" v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                <span slot="prepend">{{index + 1}}</span>
              </Input>
            </div>

          </div>
          <div class="cont" v-else-if="dataInfo.datasetData.type == 9">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadFileSuccess"
                       :show-file-list="false"
                       :disabled="!modifiable"
                       v-if="modifiable"
            >
              <div class="upload-btn">
                <Button type="primary">上传文件</Button>
              </div>
            </el-upload>
            <Button type="primary" v-else @click="downLoad(uploadPath)">下载文件</Button>
            <p class="mt10" v-if="uploadName">文件地址：{{uploadPath}}</p>
          </div>
          <div class="cont" v-else>
            <Richtxt placeholder="请输入内容" :isDisabled="!modifiable" :height="150" :dataObj="{val:shortAnswer}" @saveData="saveAnswer"></Richtxt>
          </div>
          <div class="bot">
            <div class="btn">
              <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
              <Button type="primary" @click="commitAnswer" :disabled="!modifiable">{{$t('trainingcamp_train_confirm')}}</Button>
              <Button @click="next">{{$t('trainingcamp_train_next')}}</Button>
            </div>
            <div class="op">
              <div @click="showPortrait">
                <span>单题画像</span>
              </div>
              <div @click="collect">
                <img src="../../assets/images/icon/star_active.png"  v-if="dataInfo.userCollect.id" />
                <img src="../../assets/images/icon/star.png" v-else />
                <span>{{ $t('trainingcamp_train_collection') }}</span>
              </div>
              <div @click="correction">
                <img src="../../assets/images/icon/mark.png" />
                <span>{{ $t('trainingcamp_train_correction') + '/' + $t('trainingcamp_train_feedback') }}</span>
              </div>
<!--              <div @click="feedback">-->
<!--                <img src="../../assets/images/icon/report.png" />-->
<!--                <span>{{ $t('trainingcamp_train_feedback') }}</span>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <Spin fix v-else></Spin>
      </div>
      <div class="analysis" :class="{nrBjColor: this.userSetting.eye_care ,'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}" v-if="dataInfo.userData && dataInfo.userData.answer && !userSetting.quick_view">
        <div class="answer" v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3">
          {{ $t('trainingcamp_train_stu_answer') }}：<span :class="resultInfo.stuAnswer == resultInfo.correctAnswer ? 'green' : 'red'">{{resultInfo.stuAnswer}}</span>
        </div>
        <div class="answer" v-if="resultInfo.correctAnswer">
          {{ $t('trainingcamp_train_correct_answer') }}：<span class="blue">{{resultInfo.correctAnswer}}</span>
        </div>
        <div class="cont">
          <span>{{ $t('trainingcamp_train_analysis') }}：</span>
          <span style="display: inline-block;">
          <Richtxt :dataObj="{val:resultInfo.explain}" :isInline="true" :isDisabled="true"></Richtxt>
        </span>
        </div>
      </div>
      <!--    背题模式-->
      <div class="analysis" :class="this.userSetting.eye_care ? 'nrBjColor' : ''" v-if="userSetting.quick_view">
        <div class="answer" v-if="resultInfo.correctAnswer && (dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3 || dataInfo.datasetData.type == 4)" >
          {{ $t('trainingcamp_train_correct_answer') }}：<span class="blue">{{resultInfo.correctAnswer}}</span>
        </div>
        <div class="cont">
          <span>{{ $t('trainingcamp_train_analysis') }}：</span>
          <span style="display: inline-block;">
          <Richtxt :dataObj="{val:dataInfo.datasetData && dataInfo.datasetData.explain}" :isInline="true" :isDisabled="true"></Richtxt>
        </span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="category-tit" :class="this.userSetting.eye_care ? 'nrBjColor' : ''">{{trainName}}</div>
      <div class="right-top" :class="this.userSetting.eye_care ? 'nrBjColor' : ''">
        <div class="tit">
          <div>
            {{ $t('trainingcamp_train_answer_sheet') }}
            <Tooltip>
              <Icon type="ios-help-circle-outline" />
              <div slot="content">
                <p>{{ $t('trainingcamp_train_white') }}</p>
                <p>{{ $t('trainingcamp_train_blue') }}</p>
                <p>{{ $t('trainingcamp_train_green') }}</p>
                <p>{{ $t('trainingcamp_train_red') }}</p>
              </div>
            </Tooltip>
          </div>
          <Tooltip placement="bottom" v-if="dataSort == '1' || dataSort == '10'">
            <Button type="primary" size="small" @click="redoFn" v-if="dataSort != 3">{{ $t('trainingcamp_train_again') }}</Button>
            <div slot="content" class="again">
              <p>{{ $t('trainingcamp_train_again_tip') }}</p>
              <p>{{$t('trainingcamp_train_again_check_result')}}</p>
            </div>
          </Tooltip>
        </div>

        <div class="answer-card">
          <div v-if="!answerCardLoading" class="list">
            <div :class="{active:curData.id == item.id,error:item.userData && item.userData.is_right == '0',corret:item.userData && item.userData.is_right == '1'} " v-for="(item,index) in answerCardList" :key="item.id" @click="changeAnswerCard(item,index)" style="display: flex; justify-content: center;align-items: center"><Icon type="ios-lock" v-if="item.is_free == '1'" /><span>{{index + 1}}</span></div>
          </div>
          <Spin fix v-else></Spin>
        </div>
        <div class="num">
          <div>
            {{ $t('trainingcamp_train_right') }}:<span class="blue">{{rightCount}}</span>题
          </div>
          <div>
            {{ $t('trainingcamp_train_error') }}:<span class="red">{{haveFinished - rightCount}}</span>题
          </div>
          <div>
            {{ $t('trainingcamp_train_unanswered') }}:<span>{{(answerCardList.length - haveFinished) < 0 ? 0 : answerCardList.length - haveFinished}}</span>题
          </div>
          <div>
            {{ $t('trainingcamp_train_accuracy') }}:<span>{{!rightCount ?  0 : Math.floor(rightCount / haveFinished * 100) }}%</span>
          </div>
        </div>
      </div>
      <div class="right-set" :class="this.userSetting.eye_care ? 'nrBjColor' : ''">
        <div class="tit">
          {{ $t('trainingcamp_train_set') }}
          <Tooltip>
            <Icon type="ios-help-circle-outline" />
            <div slot="content">
              {{ $t('trainingcamp_train_error_add') }}
            </div>
          </Tooltip>
        </div>
        <div class="cont">
          <div class="item">
            <!--          需要点击提交按钮 如果正确跳转下一题 如果错误出解析-->
            <span>{{ $t('trainingcamp_train_seeting_data_sort') }}</span>
            <i-switch v-model="userSetting.data_sort" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <!--          需要点击提交按钮 如果正确跳转下一题 如果错误出解析-->
            <span>智能排序</span>
            <i-switch v-model="userSetting.smart_sort" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <!--          需要点击提交按钮 如果正确跳转下一题 如果错误出解析-->
            <span>{{ $t('trainingcamp_train_correct_next') }}</span>
            <i-switch v-model="userSetting.auto_next_if_right" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <!--          不需要点击提交按钮 不会跳转下一题 选完出解析-->
            <span>{{ $t('trainingcamp_train_auto_confirm') }}</span>
            <i-switch v-model="userSetting.auto_submit" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <span>{{$t('trainingcamp_train_recitation')}}</span>
            <i-switch v-model="userSetting.quick_view" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <span>{{ $t('trainingcamp_train_set_random') }}</span>
            <i-switch v-model="userSetting.random_option" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <span>{{ $t('trainingcamp_train_set_protect_eye') }}</span>
            <i-switch v-model="userSetting.eye_care" @on-change="changeSwitch"></i-switch>
          </div>
          <div class="item">
            <span>{{ $t('trainingcamp_train_set_font_size') }}</span>
            <div class="font-size-list">
              <span :class="userSetting.font_size == item.id ? 'active' : ''" v-for="item in fontSizeList" :key="item.id" @click="changeFontSize(item)">{{ item.name }}</span>
            </div>
          </div>
<!--          <div class="item">-->
<!--            <div>-->
<!--              {{ $t('trainingcamp_train_set_continue_right') }}<Input v-model="userSetting.wrong_auto_move" type="number" style="width: 50px" @on-blur="changeSwitch" />{{ $t('trainingcamp_train_set_move') }}-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
  <DataPortrait :dataId="dataInfo.data_id" v-if="dataInfo.data_id" :portraitStatus="showPortraitStatus" @close="closePortrait"></DataPortrait>
  <Modal
      v-model="correctionModal"
      :title="$t('trainingcamp_train_correction') + '/' + $t('trainingcamp_train_feedback')"
      width="70%"
  >
    <div class="correction">
      <div class="tit">
        <div class="left">
          <p class="data-tit">纠错标题</p>
        </div>
        <div class="right" style="border: none">
          <div class="right-list">
            <div :class="item.id == correctionTitle ? 'active' : ''" v-for="(item,index) in titleList" :key="index" @click="changeCorrectionTit(item.id)">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="tit">
        <div class="left">
          <p class="data-tit">纠错内容</p>
        </div>
        <div class="right">
          <Richtxt v-if="correctionModal" placeholder="请您描述具体错误原因。" :height="150" :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>
        </div>
      </div>
      <p class="type"><strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>{{ correctionInfo.datasetData && typesObj[correctionInfo.datasetData.type]}}</p>
      <div class="tit">
        <div class="left">
          <p class="data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>
        </div>
        <div class="right">
          <Richtxt v-if="correctionInfo.datasetData" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.name}" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'name')" key="feedback"></Richtxt>
        </div>
      </div>
      <div class="option-item" v-for="(item,index) in correctionInfo.datasetDataOptions" :key="item.id">
        <div class="left">
          <p class="data-tit">{{correctionInfo.datasetData && correctionInfo.datasetData.type == '4' ? '答案' : '选项' + optionNameList[index]}}</p>
          <p class="correct" v-if="correctionInfo.datasetData && correctionInfo.datasetData.type != '4'" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>
          <p class="dele" v-if="correctionInfo.datasetDataOptions.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>
        </div>
        <div class="right">
          <Input v-if="correctionInfo.datasetData && correctionInfo.datasetData.type == '4'" v-model="item.name" type="textarea" placeholder="请输入答案" />
          <Richtxt v-else :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>
        </div>
      </div>
      <Button type="primary" @click="addOption">{{(correctionInfo.datasetData && correctionInfo.datasetData.type == '4') ? '添加答案' : $t('trainingcamp_train_correction_add_option') }}</Button>
      <div class="analysis">
        <div class="left">
          <p class="data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>
        </div>
        <div class="right">
          <Richtxt v-if="correctionInfo.datasetData" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'explain')" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.explain}" ></Richtxt>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
      <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
    </div>
  </Modal>
<!--  <Modal-->
<!--      v-model="feedbackModal"-->
<!--      :title="$t('trainingcamp_train_feedback')"-->
<!--      width="70%"-->
<!--  >-->
<!--    <Form ref="formValidate" :model="formItem" :label-width="80" :rules="ruleValidate">-->
<!--      <FormItem :label="$t('trainingcamp_train_feedback_tit')" prop="title">-->
<!--        <Input v-model="formItem.title" :placeholder="$t('trainingcamp_train_feedback_tit_placeholder')"></Input>-->
<!--      </FormItem>-->

<!--      <FormItem :label="$t('trainingcamp_train_feedback_cont')" prop="feedback">-->
<!--        <div class="feedback-richtxt">-->
<!--          <Richtxt v-if="feedbackModal" :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>-->
<!--        </div>-->
<!--        &lt;!&ndash;        <Input v-model="formItem.feedback" :maxlength="2000"  type="textarea"  placeholder="请输入反馈内容"></Input>&ndash;&gt;-->
<!--      </FormItem>-->
<!--    </Form>-->
<!--    <div slot="footer">-->
<!--      <Button @click="feedbackModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>-->
<!--      <Button type="primary" @click="confirm">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>-->
<!--    </div>-->
<!--  </Modal>-->
  <Modal
      v-model="activationModal"
      :title="$t('trainingcamp_train_avtive')"
      width="700px"
      footer-hide
  >
    <div class="activation-cont" v-if="activationList.length">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_quick') }}</p>
      <div class="activation-cont-item" v-for="item in activationList" :key="item.id">
        <div>
          <p>{{ item.code }}</p>
          <p>{{ timelongsObj[item.timelong] }}</p>
        </div>
        <p class="activation-cont-item-btn" @click="activeCode(item.code)">{{ $t('trainingcamp_train_avtive_btn') }}</p>
      </div>
    </div>
    <div class="activation-cont">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_manual') }}</p>
      <div class="activation-cont-item">
        <div>
          <Input v-model="codekey" size="large" :placeholder="$t('trainingcamp_train_code_placeholder')" style="width: 300px;" @on-change="changeCodeKey" @on-blur="getCodeInfo" />
          <p v-if="codeInfo.code">{{this.timelongsObj[codeInfo.timelong]}}</p>
        </div>
        <p class="activation-cont-item-btn" @click="activeCode(codekey,1)">{{ codeInfo.code ? $t('trainingcamp_train_avtive_confirm') : $t('trainingcamp_train_avtive_btn') }}</p>
      </div>
    </div>
    <div class="activation-cont">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_get') }}</p>
      <div class="get-code">
        <img src="../../assets/images/icon/wechat.png" width="20" height="20" />
        <p>{{ activationSite && activationSite.contact_wx }}</p>
<!--        <span>({{ $t('trainingcamp_train_wx') }})</span>-->
      </div>
      <div class="get-code">
        <img src="../../assets/images/icon/telephone.png" width="20" height="20" />
        <p>{{ activationSite && activationSite.contact_mobile }}</p>
<!--        <span>({{ $t('trainingcamp_train_wx') }})</span>-->
      </div>
      <img :src="activationSite && activationSite.contact_wx_qrcode" width="172" height="172" />
      <p class="get-code-wx" v-if="activationSite.contact_wx_qrcode">{{ $t('trainingcamp_train_wx_add') }}</p>
    </div>
  </Modal>
</div>
</template>

<script>
// import Richtxt from '../../components/production/richtxt.vue';
import Richtxt from '../../components/production/richtxt.vue';
import DataPortrait from '@/components/dataPortrait.vue';
import Cookies from "js-cookie";
import FileSaver from "file-saver";

export default {
  name: "train.vue",
  data(){
    return {
      Cookies:Cookies,
      radio:'',
      switch1:true,
      value:'0',
      categoryId:'',
      productId:'', //运营产品 ID ，小程序 1，阿里-每日一练 2  其他 1
      answerCardList:[],
      answerCardLoading:true,
      curData:{}, //当前选中数据id
      curIndex:0,
      dataInfo:{},  //数据详情
      infoLoading:true,
      typesObj:'',
      optionList:['A','B','C','D','E','F','G','H','I','J','K','L'],
      startTime:'',
      endTime:'',
      resultInfo:{
        explain:'',
        stuAnswer:'',
        correctAnswer:''
      },
      modifiable:true,
      checkbox:[],
      rightCount:0, //正确数量
      haveFinished:0, //完成数量
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      userSetting:{},
      infoCorrectAnswer:'',
      fontSizeList:[
        {
          name:this.$t('trainingcamp_train_set_font_little'),
          id:'1'
        },
        {
          name:this.$t('trainingcamp_train_set_font_standard'),
          id:'0'
        },
        {
          name:this.$t('trainingcamp_train_set_font_big'),
          id:'2'
        }
      ],
      dataSort:'',
      redo:'0',
      trainUserId:'', //用户当前题的id 如果重做会重新生成一个id
      trainId:'', //每个题库只有一个trainId
      correctionModal:false,
      optionNameList:['A','B','C','D','E','F','G'],
      correctionInfo:{},
      activationModal:false, //激活弹窗
      timelongsObj:{},
      activationSite:{},
      activationList:{},
      codekey:'', //输入的激活码
      codeInfo:{},
      generateType:'',
      showPortraitStatus:false,
      trainName:'',
      dataType:'',
      generateId: '',
      titleList:[
      ],
      correctionTitle:'',
      submitLoading:false,
      timer:null,
      courseId:'',
      courseType:'',
      fromId:'',
      fromType:'',
      shortAnswer:'',
      uploadName:'',
      uploadPath:'',
    }
  },
  components:{
    Richtxt,
    DataPortrait
  },
  created(){
    this.categoryId = this.$route.query.id || '';
    this.dataSort = this.$route.query.dataSort || '';
    this.productId = this.$route.query.product_id || '';
    this.trainId = this.$route.query.train_id || '';
    this.trainUserId = this.$route.query.train_user_id || '';
    this.generateType = this.$route.query.generateType || '';
    this.dataType = this.$route.query.data_type || '';
    this.generateId = this.$route.query.generate_id || '';
    this.fromId = this.$route.query.from_id || '';
    this.fromType = this.$route.query.from_type || '';
    this.courseType = this.$route.query.type || '';

    this.getAnswerCard();
    this.getActiveForm();
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods:{
    changeCorrectionTit(data){
      this.correctionTitle = data;
    },
    showPortrait(){
      this.showPortraitStatus = true;
    },
    closePortrait(){
      console.log('12121212')
      this.showPortraitStatus = false;
      console.log(this.showPortraitStatus,'this.showPortraitStatus')
    },
    redoFn(){
      this.redo = '1';
      this.radio = '';
      this.checkbox = [];
      this.modifiable = true;
      this.getAnswerCard();
    },
    getAnswerCard(index){
      let params = {
        action:'testList',
        // category_id:this.categoryId,
        product_id:this.productId,
        data_rule:this.dataSort,
        redo:this.redo,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      if(this.generateType == 2){ //自有题库
        params.group_id = this.categoryId;
      }else{
        if(this.dataSort == 3){
          if(this.trainId){
            params.train_id = this.trainId;
          }
        }else{
          params.category_id = this.categoryId;
          if(this.trainId){
            params.train_id = this.trainId;
          }
        }
        // params.category_id = this.categoryId;
      }
      if(this.dataType){
        params.data_type = this.dataType;
      }
      if(this.generateId){
        params.generate_id = this.generateId;
      }
      if(this.fromId){
        params.from_id = this.fromId;
      }
      if(this.fromType){
        params.from_type = this.fromType;
      }
      if(this.courseType){
        params.type = this.courseType;
      }



      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.answerCardLoading = false;
        this.redo = '0';  //请求结束后还原
        if(res.data_list.length){
          this.answerCardList = res.data_list;
          this.typesObj = res.types;

          this.userSetting = res.user_setting;

          this.userSetting.auto_next_if_right = this.userSetting.auto_next_if_right == '1' ? true : false;
          this.userSetting.auto_submit = this.userSetting.auto_submit == '1' ? true : false;
          this.userSetting.quick_view = this.userSetting.quick_view == '1' ? true : false;
          console.log(this.userSetting.quick_view,'this.userSetting.quick_viewthis.userSetting.quick_view')
          this.userSetting.random_option = this.userSetting.random_option == '1' ? true : false;
          this.userSetting.eye_care = this.userSetting.eye_care == '1' ? true : false;
          this.userSetting.font_size = this.userSetting.font_size;
          // this.userSetting.wrong_auto_move = this.userSetting.wrong_auto_move;
          this.userSetting.data_sort = this.userSetting.data_sort == '1' ? true : false;
          this.userSetting.smart_sort = this.userSetting.smart_sort == '1' ? true : false;

          // this.curIndex = index ? index : 0;
          if(res.user_next_index && res.user_next_index != '0'){
            if(res.user_next_index > this.answerCardList.length - 1){
              this.curIndex = this.answerCardList.length - 1;
            }else{
              this.curIndex = Number(res.user_next_index);
            }

          }else{
            this.curIndex = 0;
          }
          this.curData = this.curIndex ? this.answerCardList[this.curIndex] : this.answerCardList[0];

              // console.log(this.curIndex,res.user_next_index,'res.user_next_index')
          this.rightCount = Number(res.train_user.right_count);
          this.haveFinished = Number(res.train_user.have_finished);

          this.trainUserId = res.train_user.id;
          this.trainId = res.train.id;
          this.trainName = res.train.name;

          this.titleList = [];
          for(let name in res.data_correction_types){
            this.titleList.push({
              name:res.data_correction_types[name],
              id:name,
            })
          }

          this.getInfo(); //获取数据详情
        }else{
          this.$Message.warning(this.$t('trainingcamp_train_no_data'));
          this.timer = setTimeout(()=>{
            this.$router.go(-1)
          },2000)

        }
      }).catch((err)=>{
        this.answerCardLoading = false;
      })
    },
    getInfo(){  //获取详情数据
      let params = {
        action:'testListInfo',
        product_id:this.productId,
        group_id:this.curData.train_id,
        data_id:this.curData.data_id,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      this.infoLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.infoLoading = false;
        this.dataInfo = res[0];
        this.formatInfoData(res[0]);

        this.startTime = this.getTime();
      }).catch((err)=>{
        this.infoLoading = false;
      })
    },
    setIndex(){  //记录当前位置
      let params = {
        action:'reportTrainUserNextIndex',
        train_user_id:this.trainUserId,
        train_id:this.trainId,
        user_next_index:this.curIndex,
      };
      this.api.dataset.trainExecute(params).then((res)=>{

      })
    },
    getTime(){
      let oDate = new Date();
      let time = Math.floor(oDate.getTime() / 1000);

      return time
    },
    resetSelectAnswer(){
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断
        this.radio = '';
      }
      if(this.dataInfo.datasetData.type == '2') {  //2多选
        this.checkbox = [];
      }
      this.$set(this.resultInfo, 'stuAnswer', '');
      this.$set(this.resultInfo, 'correctAnswer', '');
    },
    changeAnswerCard(data,index){
      if(data.is_free == '1'){
        // this.$Message.warning('暂无权限');
        this.activationModal = true;
        return;
      }
      this.modifiable = true;
      this.curData = data;
      this.curIndex = index;

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
      this.setIndex();
    },
    next(){ //下一题
      if(this.curIndex == this.answerCardList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      if(this.answerCardList[this.curIndex + 1].is_free == '1'){
        // this.$Message.warning('暂无权限');
        this.activationModal = true;
        return;
      }
      this.curIndex = this.curIndex + 1;
      this.modifiable = true;
      this.curData = this.answerCardList[this.curIndex];

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
      this.setIndex();
    },
    pre(){  //上一题
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      if(this.answerCardList[this.curIndex - 1].is_free == '1'){
        // this.$Message.warning('暂无权限');
        this.activationModal = true;
        return;
      }
      this.curIndex = this.curIndex - 1;
      this.modifiable = true;
      this.curData = this.answerCardList[this.curIndex];

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
      this.setIndex();
    },
    saveAnswer(data){
      this.shortAnswer = data;
    },
    commitAnswer(){ //提交答案
      if(this.submitLoading){
        return;
      }
      this.endTime = this.getTime();
      let date = this.endTime - this.startTime;

      let answer_data = [
        {
          id:this.curData.data_id,
          use_time:date
        }
      ];
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3'){  //1单选 3判断
        if(!this.radio){
          this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        let data = this.radio.substring(3);
        let answer = this.dataInfo.datasetDataOptions.filter((item)=>{
          return item.name == data;
        })[0].id;
        answer_data[0].answer = answer;
      }
      if(this.dataInfo.datasetData.type == '2'){  //2 多选
        if(!this.checkbox.length){
          this.$Message.warning(this.$t('trainingcamp_train_select_answer'));
          return;
        }
        let arr = this.checkbox.map((item)=>{
          return item.substring(3);
        })

        let answer = [];
        this.dataInfo.datasetDataOptions.forEach((item)=>{
          let num = arr.findIndex((sItem)=>{
            return sItem == item.name;
          });
          if(num > -1){
            answer.push(item.id);
          }
        })
        answer_data[0].answer = answer.join(',');
      }
      if(this.dataInfo.datasetData.type == '4'){
        let answer = this.dataInfo.datasetDataOptions.map((item)=>{
          return item.myAnswer;
        })
        answer_data[0].answer = answer.join(',');
      }

      if(this.dataInfo.datasetData.type == '9'){  //实操
        answer_data[0].answer = this.uploadPath;
      }
      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10'){  //简答、填空、实验
        answer_data[0].answer = this.shortAnswer;
      }


      let params = {
        action:'exerciseSubmit',
        product_id:this.productId,
        use_time:date,
        answer_data:JSON.stringify(answer_data),
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      this.submitLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.submitLoading = false;
        if(!this.dataInfo.userData){
          this.$set(this.dataInfo,'userData',{});
        }
        if(!this.dataInfo.userData.answer){
          this.$set(this.dataInfo.userData,'answer',answer_data[0].answer);
        }else{
          this.dataInfo.userData.answer = answer_data[0].answer;
        }

        this.formatInfoData(this.dataInfo,'commit',res.data_list[0].userData.is_right);
      }).catch((err)=>{
        this.submitLoading = false;
      })
    },
    formatInfoData(data,type,isRight){
      this.resultInfo.explain = data.datasetData.explain;
      let is_right;
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断

        let index = data.datasetDataOptions.findIndex((item) => {
          return data.userData.answer == item.id
        });
        let correctIndex = data.datasetDataOptions.findIndex((item) => {
          return item.is_correct == 1;
        });
        if(type == 'commit'){ //提交答案 更新答题卡数据  是否正确
          is_right = index == correctIndex ? '1' : '0';
          this.$set(this.answerCardList[this.curIndex],'userData',{})
          this.$set(this.answerCardList[this.curIndex].userData,'is_right',is_right);
          this.count(is_right);
        }

        this.$set(this.resultInfo, 'stuAnswer', this.optionList[index]);
        this.$set(this.resultInfo, 'correctAnswer', this.optionList[correctIndex]);
        if(data.userData.answer){
          this.modifiable = false;
        }
        if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
          if(!type){
            this.infoCorrectAnswer = this.optionList[correctIndex];
            this.radio = this.optionList[correctIndex] + ': ' + data.datasetDataOptions[correctIndex].name;
          }
          this.modifiable = false;
        }else{
          console.log('222222222222')
          if(data.userData.answer){
            this.radio = this.optionList[index] + ': ' + data.datasetDataOptions[index].name;
          }else{
            this.modifiable = true;
          }
        }
      }

      if(this.dataInfo.datasetData.type == '2') {  //2多选
        let answerArr = data.userData.answer && data.userData.answer.split(',');
        //学生答案的index  正确答案的index
        let stuAnswerIndex = [];
        let correctAnswerIndex = [];
        data.datasetDataOptions.forEach((item,index)=>{
          if(answerArr){
            let num = answerArr.findIndex((sItem)=>{
              return sItem == item.id
            });
            if(num > -1){
              stuAnswerIndex.push(index);
            }
          }

          if(item.is_correct == '1'){
            correctAnswerIndex.push(index);
          }
        })
        if(type == 'commit'){ //提交答案 更新答题卡数据  是否正确
          is_right = stuAnswerIndex.join(',') === correctAnswerIndex.join(',') ? '1' : '0';
          this.$set(this.answerCardList[this.curIndex],'userData',{})
          this.$set(this.answerCardList[this.curIndex].userData,'is_right',is_right)
          this.count(is_right);
        }
        let stuAnswer = [];
        let checkoutArr = [];
        let correctCheckoutArr = [];

        stuAnswerIndex.forEach((item)=>{
          stuAnswer.push(this.optionList[item])
          checkoutArr.push( this.optionList[item] + ': ' + data.datasetDataOptions[item].name)
        })

        let correctAnswer = [];
        correctAnswerIndex.forEach((item)=>{
          correctAnswer.push(this.optionList[item]);
          correctCheckoutArr.push(this.optionList[item] + ': ' + data.datasetDataOptions[item].name)
        });



        if(stuAnswer.length){
          this.$set(this.resultInfo, 'stuAnswer', stuAnswer.join(','));
        }else{
          this.$set(this.resultInfo, 'stuAnswer', '');
        }

        if(correctAnswer.length){
          this.$set(this.resultInfo, 'correctAnswer', correctAnswer.join(','));
        }else{
          this.$set(this.resultInfo, 'correctAnswer', '');
        }

        if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
          if(!type){
            this.infoCorrectAnswer = this.optionList[correctAnswerIndex];
            this.checkbox = correctCheckoutArr;
          }
          this.modifiable = false;
        }else{
          if(data.userData.answer){
            this.checkbox = checkoutArr;
          }else{
            this.modifiable = true;
          }

        }
        if(data.userData.answer){
          this.modifiable = false;
        }

      }
      console.log(this.dataInfo.datasetData.type,'this.dataInfo.datasetData.type')
      if(this.dataInfo.datasetData.type == '4') {  //4填空
        let answer;
        if(data.userData.answer){
          answer = data.userData.answer.split(',');
          data.datasetDataOptions.forEach((item,index)=>{
            if(answer[index]){
              this.$set(item,'myAnswer',answer[index]);
            }else{
              this.$set(item,'myAnswer','');
            }
          })
        }
        let correctAnswer = this.dataInfo.datasetDataOptions.map((item)=>{
          return item.name;
        }).join(',')

        this.$set(this.resultInfo, 'correctAnswer', correctAnswer);

        if(type == 'commit'){ //提交答案 更新答题卡数据  是否正确

          this.$set(this.answerCardList[this.curIndex],'userData',{})

          this.$set(this.answerCardList[this.curIndex].userData,'is_right',isRight)
          this.count(isRight);
        }
        if(data.userData.answer){
          this.modifiable = false;
        }else{
          if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
            this.modifiable = false;
          }else{
            this.modifiable = true;
          }
        }
      }

      if(this.dataInfo.datasetData.type == '9') { //实操

        if(data.userData.answer){
          let urlArr = data.userData.answer.split('/');
          this.uploadName = urlArr[urlArr.length -1];
          this.uploadPath = data.userData.answer;



        }else{
          this.uploadName ='';
          this.uploadPath = '';
        }

        if(type == 'commit'){ //提交答案 更新答题卡数据  是否正确

          this.$set(this.answerCardList[this.curIndex],'userData',{})

          this.$set(this.answerCardList[this.curIndex].userData,'is_right',isRight)
          this.count(isRight);
        }
        if(data.userData.answer){
          this.modifiable = false;
        }else{
          if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
            this.modifiable = false;
          }else{
            this.modifiable = true;
          }
        }

      }

      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10') { //简答、填空、实验
        this.shortAnswer = data.userData.answer;

        if(type == 'commit'){ //提交答案 更新答题卡数据  是否正确

          this.$set(this.answerCardList[this.curIndex],'userData',{})

          this.$set(this.answerCardList[this.curIndex].userData,'is_right',isRight)
          this.count(isRight);
        }
        if(data.userData.answer){
          this.modifiable = false;
        }else{
          if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
            this.modifiable = false;
          }else{
            this.modifiable = true;
          }
        }
      }

      if(type == 'commit'){
        if(this.userSetting.auto_next_if_right && is_right == '1'){  //答对自动下一题
          this.next();
        }
      }

    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.uploadPath = response.data.info.upload_path;
      this.uploadName = response.data.info.name;
    },
    count(is_right){
      if(is_right == '1'){
        this.rightCount++;
        this.haveFinished++;
      }else{
        this.haveFinished++;
      }
    },
    collect(){  //收藏
      let params = {
        action:'userCollect',
        product_id:this.productId,
        type:'0',
        data_id:this.curData.data_id,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.$Message.success(res);
        if(res == '取消收藏成功'){
          this.$set(this.dataInfo,'userCollect',{});
        }else{
          this.$set(this.dataInfo.userCollect,'id',1);
        }
      })
    },
    feedback(){
      this.feedbackModal = true;
    },
    confirm(){  //反馈确定
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          let params = {
            action:'feedback',
            product_id:this.productId,
            data_id:this.curData.data_id,
            title:this.formItem.title,
            content:this.formItem.feedback,
            train_user_id:this.trainUserId,
            train_id:this.trainId,
          };
          this.api.dataset.trainExecute(params).then((res)=>{
            this.$Message.success(this.$t('trainingcamp_train_feedback_success'));
            this.feedbackModal = false;
          })
        }
      })
    },
    saveRichData(data){
      this.formItem.feedback = data;
    },
    changeSwitch(){ //设置
      let params = {
        action:'userSetting',
        product_id:this.productId,
        auto_next_if_right:this.userSetting.auto_next_if_right ? '1' : '0',
        auto_submit:this.userSetting.auto_submit ? '1' : '0',
        quick_view:this.userSetting.quick_view ? '1' : '0',
        random_option:this.userSetting.random_option ? '1' : '0',
        eye_care:this.userSetting.eye_care ? '1' : '0',
        font_size:this.userSetting.font_size,
        // wrong_auto_move:this.userSetting.wrong_auto_move,
        train_user_id:this.trainUserId,
        train_id:this.trainId,
        data_sort:this.userSetting.data_sort ? '1' : '0',
        smart_sort:this.userSetting.smart_sort ? '1' : '0'
      }
      this.api.dataset.trainExecute(params).then((res)=>{
        this.$Message.success(this.$t('trainingcamp_train_set_success'));
        this.radio = '';
        this.checkbox = [];
        this.getAnswerCard(this.curIndex);
      })
    },
    changeRadio(){  //选择单选题
      if(this.userSetting.auto_submit){//自动提交答案
        this.commitAnswer();
      }
    },
    changeFontSize(data){
      this.userSetting.font_size = data.id;
      this.changeSwitch();
    },
    correction(){
      this.correctionModal = true;
      this.correctionInfo = JSON.parse(JSON.stringify(this.dataInfo));
      this.correctionTitle = '';
      this.formItem.feedback = '';
    },
    saveCurData(curData,data,name){
      console.log('eeeeeee',curData)
      data[name] = curData;
    },
    changeCorrect(data){  //修改正确选项
      if(this.correctionInfo.datasetData.type == '1' || this.correctionInfo.datasetData.type == '3'){ //单选
        if(data.is_correct == '0'){
          this.correctionInfo.datasetDataOptions.forEach((item)=>{
            item.is_correct = '0';
          });
          this.$nextTick(()=>{
            data.is_correct = '1';
          })

          console.log(data)
        }else{
          this.$nextTick(()=>{
            data.is_correct = '0';
          })
        }
      }else{
        data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
      }

    },
    addOption(){  //添加选项
      if(this.correctionInfo.datasetDataOptions.length == 6){
        this.$Message.warning(this.$t('trainingcamp_train_option_max'));
        return;
      }
      this.correctionInfo.datasetDataOptions.push({
        name:'',
        id:'0',
        is_correct:'0'
      })
    },
    deleOption(index){  //删除选项
      this.correctionInfo.datasetDataOptions.splice(index, 1);
    },
    confirmCorrection(){  //确认纠错
      if(!this.correctionTitle){
        this.$Message.warning('请输入纠错标题');
        return;
      }
      if(this.correctionTitle == 4 && !this.formItem.feedback){
        this.$Message.warning('请输入纠错内容');
        return;
      }
      // if(!this.correctionInfo.datasetData.name){  //标题
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
      //   return;
      // }
      // if(!this.correctionInfo.datasetDataOptions.length){ //选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
      //   return;
      // }
      // let len = this.correctionInfo.datasetDataOptions.filter((item)=>{
      //   return item.is_correct == '1';
      // }).length
      // if(!len){ //正确选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
      //   return;
      // }
      // for(let i=0;i<this.correctionInfo.datasetDataOptions.length;i++){
      //   if(!this.correctionInfo.datasetDataOptions[i].name){
      //     this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
      //     return;
      //     break;
      //   }
      // }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }
      console.log(this.correctionInfo.datasetDataOptions,'this.correctionInfo.datasetDataOptions')
      if(this.correctionInfo.datasetData.type == 4){
        this.correctionInfo.datasetDataOptions.forEach((item)=>{
          item.is_correct = '1';
        })
      }

      let params = {
        data_id:this.correctionInfo.data_id,
        name:this.correctionInfo.datasetData.name,
        explain:this.correctionInfo.datasetData.explain,
        options:JSON.stringify(this.correctionInfo.datasetDataOptions),
        type:this.correctionInfo.datasetData.type,
        level:this.correctionInfo.datasetData.level,
        err_type:this.correctionTitle,
        err_message:this.formItem.feedback
      };

      this.api.dataset.correctionCreate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_correction_success'));
        // this.getInfo()
      })
    },
    getActiveForm(){
      let params = {
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActiveForm(params).then((res)=>{
        this.timelongsObj = res.timelongs;
        this.activationList = res.list;
        this.activationSite = res.site;
      })
    },
    changeCodeKey(){
      this.codeInfo = {};
    },
    getCodeInfo(){  //获取code详情
      if(!this.codekey){
        return;
      }
      let params = {
        code:this.codekey,
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActive(params).then((res)=>{
        this.codeInfo = res.info;
      })
    },
    activeCode(data,type){ //激活
      if(!data){
        this.$Message.warning(this.$t('trainingcamp_train_code_placeholder'));
        return;
      }
      if(type == 1 && !this.codeInfo.code){
        this.$Message.warning(this.$t('trainingcamp_train_avtive_confirm_message'));
        return;
      }
      let params = {
        code:data,
        is_sure:'1',
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActive(params).then((res)=>{
        this.$Message.success(this.$t('trainingcamp_train_avtive_success'));
        this.activationModal = false;
        this.getAnswerCard();
      })
    },
    downLoad(data){
      let name = data.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data,name);
    },
  }
}
</script>

<style scoped lang="scss">
.train{
  padding: 20px;

  font-size: 14px;

  .train-cont{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    .left-top{
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .analysis{
      margin-top: 20px;
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;

      .answer{
        margin-bottom: 10px;

        .blue{
          color: #2d8cf0;
        }
        .green{
          color: #19be6b;
        }
        .red{
          color: #ed4014;
        }
      }
      .cont{
        //font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }
  .right{
    width: 400px;
    .category-tit{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      border: 1px solid #dcdee2;
      padding: 20px;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;
        height: 380px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }

          >div.error{
            background-color: #ec808d ;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
    .right-set{
      margin-top: 20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
      }
      .cont{
        margin:20px;
        .item{
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .font-size-list{
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >span{
              margin-left:30px;
              cursor: pointer;
            }
            >span:nth-child(2){
              font-size: 16px;
            }
            >span:nth-child(3){
              font-size: 18px;
            }
            >span.active{
              color:#2d8cf0;
            }
          }
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;

      .right-list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 10px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
          padding: 5px 10px;
          text-align: center;
          cursor: pointer;
        }
        .active{
          border: 1px solid #2d8cf0;
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }
  }
  .analysis{
    margin-top: 20px;
  }
}

.activation-cont{
  margin-bottom: 20px;
  padding: 20px;
  background: #f8f8f9;
  border-radius: 4px;
  font-size: 14px;

  .activation-cont-tit{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .activation-cont-item{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 36px;
      line-height: 36px;

      >p{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        height: 100%;
      }
      >p:nth-child(1){
        width: 300px;
        text-align: center;
      }
      >p:nth-child(2){
        width: 150px;
        text-align: center;
      }
    }
    .activation-cont-item-btn{
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background-color: #2d8cf0;
      color:#FFFFFF;
      border-radius: 4px;
      cursor: pointer;
    }

  }
  .get-code{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >p{
      padding: 0 5px;
      color: #2d8cf0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .get-code-wx{
    margin-top: 10px;
    width: 172px;
    text-align: center;
  }
}
.cont-input{
  margin-bottom: 20px;
}
</style>
<style>
.train .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
.train-img-w img{
  width: 100%;
}
/*.ivu-modal-content{*/
/*  background: #f8f8f9;*/
/*}*/
</style>
